:root {
  --orange: #f4f3fa;
  --black: #010105;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
  --white-light: rgba(255, 255, 255, 0.5);
  --alice-blue: #f4f7fa;
  --carribean-green: #40c9a2;
  --gray: #ededed;
  --milk: #feffff;
}
:root {
  --orange: #f4f3fa;
  --black: #010105;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}
.video-container video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 1600px;
  /* height: 1950px; */
  text-align: center;
  align-items: center;
}
.back-video {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
}

body {
  background: white;
}

section {
  padding: 2rem 9%;
}

.heading {
  text-align: center;
  padding: 2rem 0;
  padding-bottom: 3rem;
  font-size: 3.5rem;
  color: var(--black);
}

.heading span {
  background: var(--black);
  color: rgb(241, 244, 245);
  display: inline-block;
  padding: 0.5rem 3rem;
  clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.8rem 3rem;
  font-size: 1.7rem;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--black);
  color: var(--black);
  cursor: pointer;
  background: rgb(131, 198, 237);
}

.btn:hover {
  background: var(--black);
  color: rgb(131, 198, 237);
}

.header {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 5%;
  background: rgb(241, 242, 244);
  box-shadow: var(--box-shadow);
}

.header .logo {
  font-size: 6rem;
  top: 10px;
  left: 20px;
  right: 0px;
  font-weight: bolder;
  color: var(--black);
}

.header .navbar a {
  font-size: 1.7rem;
  margin: 2rem;
  color: var(--black);
}

.header .navbar a:hover {
  color: rgb(131, 198, 237);
}

.header .icons div {
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  border-radius: 0.5rem;
  background: rgb(131, 198, 237);
  color: var(--white);
  font-size: 2rem;
  margin-left: 0.3rem;
  cursor: pointer;
  text-align: center;
}

.header .icons div:hover {
  background: var(--orange);
  color: rgb(131, 198, 237);
}

#menu-btn {
  display: none;
}

.header .search-form {
  position: absolute;
  top: 110%;
  right: -110%;
  width: 50rem;
  height: 5rem;
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
}

.header .search-form.active {
  right: 2rem;
  transition: 0.4s linear;
}

.header .search-form input {
  height: 100%;
  width: 100%;
  background: none;
  text-transform: none;
  font-size: 1.6rem;
  color: var(--black);
  padding: 0 1.5rem;
}

.header .search-form label {
  font-size: 2.2rem;
  padding-right: 1.5rem;
  color: var(--black);
  cursor: pointer;
}

.header .search-form label:hover {
  color: var(--orange);
}

.header .shopping-cart {
  position: absolute;
  top: 110%;
  right: -110%;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  width: 35rem;
  background: #fff;
}

.header .shopping-cart.active {
  right: 2rem;
  transition: 0.4s linear;
}

.header .shopping-cart .box {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  margin: 1rem 0;
}

.header .shopping-cart .box img {
  height: 10rem;
}

.header .shopping-cart .box .fa-trash {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  cursor: pointer;
  color: var(--light-color);
  transform: translateY(-50%);
}

.header .shopping-cart .box .fa-trash:hover {
  color: var(--orange);
}

.header .shopping-cart .box .content h3 {
  color: var(--black);
  font-size: 1.7rem;
  padding-bottom: 1rem;
}

.header .shopping-cart .box .content span {
  color: var(--light-color);
  font-size: 1.6rem;
}

.header .shopping-cart .box .content .quantity {
  padding-left: 1rem;
}

.header .shopping-cart .total {
  font-size: 2.5rem;
  padding: 1rem 0;
  text-align: center;
  color: var(--black);
}

.header .shopping-cart .btn {
  display: block;
  text-align: center;
  margin: 1rem;
}

.header .login-form {
  position: absolute;
  top: 110%;
  right: -110%;
  width: 35rem;
  box-shadow: var(--box-shadow);
  padding: 2rem;
  border-radius: 0.5rem;
  background: #fff;
  text-align: center;
}

.header .login-form.active {
  right: 2rem;
  transition: 0.4s linear;
}

.header .login-form h3 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: var(--black);
}

.header .login-form .box {
  width: 100%;
  margin: 0.7rem 0;
  background: #eee;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
}

.header .login-form p {
  font-size: 1.4rem;
  padding: 0.5rem 0;
  color: var(--dark-color);
}

.header .login-form p a {
  color: var(--carribean-green);
  text-decoration: underline;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url(../image/banner-bg.webp) no-repeat; */
  background-position: center;
  background-size: cover;
  padding-top: 20rem;
  padding-bottom: 10rem;
}

.home .content {
  text-align: center;
  width: 50em;
}

.home .content h3 {
  color: var(--black);
  font-size: 3.5rem;
}

.home .content h3 span {
  color: var(--orange);
}

.home .content p {
  color: var(--dark-color);
  font-size: 2.5rem;
  padding: 1rem 0;
  line-height: 1.8;
}

.features .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
}

.features .box-container .box {
  padding: 3rem 2rem;
  background: #fff;
  outline: var(--outline);
  outline-offset: -1rem;
  text-align: center;
  box-shadow: var(--box-shadow);
}

.features .box-container .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.features .box-container .box img {
  margin: 1rem 0;
  height: 15rem;
}

.features .box-container .box h3 {
  font-size: 2.5rem;
  line-height: 1.8;
  color: var(--black);
}

.features .box-container .box p {
  font-size: 1.5rem;
  line-height: 1.8;
  color: var(--light-color);
  padding: 1rem 0;
}

.categories .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  gap: 1.5rem;
}

.categories .box-container .box {
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  outline: var(--outline);
  outline-offset: -1rem;
  text-align: center;
}

.categories .box-container .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.categories .box-container .box img {
  margin: 1rem 0;
  height: 15rem;
}

.categories .box-container .box h3 {
  font-size: 2rem;
  color: var(--black);
  line-height: 1.8;
}

.categories .box-container .box p {
  font-size: 1.7rem;
  color: var(--light-color);
  line-height: 1.8;
  padding: 1rem 0;
}

.blogs .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
}

.blogs .box-container .box {
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  background: #fff;
}

.blogs .box-container .box img {
  height: 25rem;
  width: 100%;
  object-fit: cover;
}

.blogs .box-container .box .content {
  padding: 2rem;
}

.blogs .box-container .box .content .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a {
  color: var(--light-color);
  font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover {
  color: var(--black);
}

.blogs .box-container .box .content .icons a i {
  color: var(--orange);
  padding-right: 0.5rem;
}

.blogs .box-container .box .content h3 {
  line-height: 1.8;
  color: var(--black);
  font-size: 2.2rem;
  padding: 0.5rem 0;
}

.blogs .box-container .box .content p {
  line-height: 1.8;
  color: var(--light-color);
  font-size: 1.5rem;
  padding: 0.5rem 0;
}

.footer {
  background: grey;
}

.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;
}

.footer .box-container .box h3 {
  font-size: 3.5rem;
  color: var(--black);
  padding: 1rem 0;
}

.footer .box-container .box h3 i {
  color: var(--dark-color);
}

.footer .box-container .box .links {
  display: block;
  font-size: 1.5rem;
  color: var(--dark-color);
  padding: 1rem 0;
}

.footer .box-container .box .links i {
  color: var(--dark-color);
  padding-right: 0.5rem;
}

.footer .box-container .box .links:hover i {
  padding-right: 2rem;
}

.footer .box-container .box p {
  line-height: 1.8;
  font-size: 1.5rem;
  color: var(--dark-color);
  padding: 1rem 0;
}

.footer .box-container .box .share a {
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  border-radius: 0.5rem;
  font-size: 2rem;
  color: var(--black);
  margin-right: 0.2rem;
  background: #eee;
  text-align: center;
}

.footer .box-container .box .share a:hover {
  background: var(--orange);
  color: #fff;
}

.footer .box-container .box .email {
  width: 100%;
  margin: 0.7rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #eee;
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
}

.footer .box-container .box .payment-img {
  margin-top: 2rem;
  height: 3rem;
  display: block;
}

.footer .credit {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2.5rem;
  font-size: 2rem;
  color: var(--black);
  border-top: var(--border);
}

.footer .credit span {
  color: var(--orange);
}

/* product section */

.container {
  width: 88vw;
  margin: 0 auto;
}
.lg-title,
.md-title,
.sm-title {
  font-family: "Roboto", sans-serif;
  padding: 0.6rem 0;
  text-transform: capitalize;
}
.lg-title {
  font-size: 4.5rem;
  font-weight: 700;
  text-align: center;
  padding: 15rem 0;
  opacity: 9.9;
}
.md-title {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}
.sm-title {
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
}
.text-light {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  opacity: 0.5;
  margin: 0.4rem 0;
}

.products {
  background: var(--alice-blue);
  padding: 9.2rem 0;
}
.products {
  background-image: url("https://github.com/Matsya-03161013/matsya/blob/main/FISHERMAN.jpg?raw=true");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.products .text-light {
  text-align: center;
  width: 70%;
  margin: 0.9rem auto;
}
.product {
  margin: 2rem;
  position: relative;
}
.product-content {
  background: #feffff;
  padding: 3rem 1rem 1rem 1rem;
  cursor: pointer;
}
.product-img {
  background: #feffff;
  box-shadow: 0 0 20px 10px #feffff;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 50%;
  transition: background 0.5s ease;
}
.product-btns {
  display: flex;
  justify-content: center;
  margin-top: 1.4rem;
  opacity: 0;
  transition: opacity 0.6s ease;
}
.btn-cart,
.btn-buy {
  background: transparent;
  border: 1px solid black;
  padding: 0.8rem 0;
  width: 125px;
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  transition: all 0.6s ease;
}
.btn-cart {
  background: black;
  color: white;
}
.btn-cart:hover {
  background: var(--carribean-green);
}
.btn-buy {
  background: white;
}
.btn-buy:hover {
  background: var(--carribean-green);
  color: #fff;
}
.product-info {
  background: rgb(255, 254, 254);
  padding: 2rem;
}
.product-info-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rating span {
  color: var(--carribean-green);
}
.product-name {
  color: black;
  display: block;
  text-decoration: none;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}
.product-price {
  padding-top: 0.6rem;
  padding-right: 0.6rem;
  display: inline-block;
}
.product-price:first-of-type {
  text-decoration: line-through;
  color: var(--carribean-green);
}
.product-img img {
  transition: transform 0.6s ease;
}
.product:hover .product-img img {
  transform: scale(1.1);
}
.product:hover .product-img {
  background: var(black);
}
.product:hover .product-btns {
  opacity: 1;
}
.off-info .sm-title {
  background: var(--carribean-green);
  color: white;
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  z-index: 1;
  letter-spacing: 3px;
  cursor: pointer;
}

/* product collection */
.product-collection {
  padding: 3.2rem 0;
}
.product-collection-wrapper {
  padding: 3.2rem 0;
}
.product-col-left {
  /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url("images/fashion-img-1.jpg") center/cover no-repeat; */
}
.product-col-r-top {
  /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url("images/fashion-img-2.png") center/cover no-repeat; */
}
.flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50vh;
  padding: 2rem 1.5rem 3.2rem;
  margin: 5px;
}
.product-col-r-bottom > div:first-child {
  background: #eaa001;
}
.product-col-r-bottom > div:last-child {
  background: #0090ff;
}
.product-col-content {
  text-align: center;
  color: white;
}
.product-collection .text-light {
  opacity: 1;
  font-size: 0.8;
  font-weight: 400;
  line-height: 1.7;
}
.btn-dark {
  background: black;
  color: white;
  outline: 0;
  border-radius: 25px;
  padding: 0.7rem 1rem;
  border: 0;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.6s ease;
  font-size: 1rem;
  font-family: inherit;
}
.btn-dark:hover {
  background: var(--carribean-green);
}

/* media queries  */

@media (max-width: 991px) {
  html {
    font-size: 55%;
  }

  .header {
    padding: 2rem;
  }

  section {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  #menu-btn {
    display: inline-block;
  }

  .header .search-form {
    width: 90%;
  }

  .header .navbar {
    position: absolute;
    top: 110%;
    right: -110%;
    width: 30rem;
    box-shadow: var(--box-shadow);
    border-radius: 0.5rem;
    background: #fff;
  }

  .header .navbar.active {
    right: 2rem;
    transition: 0.4s linear;
  }

  .header .navbar a {
    font-size: 2rem;
    margin: 2rem 2.5rem;
    display: block;
  }
}

.checkout {
  padding: 10rem 0rem;
}

.checkout .container .order-details {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  justify-content: space-between;
}

.pane {
  background-color: #fff;
  padding: 5rem;
  box-shadow: 1px 1px #888888;
  border-radius: 4px;
}

.left-pane {
  width: 65%;
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
}

.right-pane {
  width: 33%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
}

.cart-item {
  display: flex;
  grid-gap: 3rem;
}

.container hr {
  color: black;
  height: 1px;
  background-color: gray;
}

.item-image img {
  width: 10rem;
  height: 7rem;
}

.item-details {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  width: 100%;
}

.md-title {
  font-size: 2.4rem;
  font-weight: 600;
  padding: unset;
}

.gray-text {
  color: var(--light-color);
}

.green-text {
  color: var(--carribean-green);
}

.h4-text {
  font-size: 1.6rem;
  font-weight: 600;
}

.h3-text {
  font-size: 2rem;
  font-weight: 600;
}

.price-details {
  display: flex;
  justify-content: space-between;
}

.price-item {
  font-size: 2rem;
  color: var(--light-color);
  display: flex;
  justify-content: space-between;
}

.address-form {
  margin-top: 3rem;
  background-color: #fff;
  padding: 5rem;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
}

.address-box {
  width: 40%;
  margin: 0.7rem 0;
  background: #eee;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
}

.ck-forms {
  margin-top: 3rem;
  padding: 5rem;
  width: 100%;
}

.checkout-btn {
  display: flex;
  flex-direction: row-reverse;
}

.btn-confirm {
  width: 24rem;
  padding: 2rem;
  background: var(--carribean-green);
  color: #fff;
  border: 0.2rem solid var(--carribean-green);
}

.btn-confirm:hover {
  color: var(--carribean-green);
  background: var(--milk);
}

.red-box {
  border: 1px solid #ed5249;
}

.bg-white {
  background-color: var(--milk);
}

.loggedin-user {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
}
.loggedin-user h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.loggedin-user h3 span {
  color: var(--carribean-green);
}
.addr-title {
  width: 100%;
}

.pay-methods {
  display: inline;
  margin-top: 2rem;
  display: flex;
  grid-gap: 1rem;
  font-size: 2rem;
}

.pay-methods input {
  width: 2rem;
}

.confirm-details {
  padding: 10rem;
}

.confirm-details h2 {
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.confirm-details h2 span {
  color: var(--carribean-green);
  font-weight: bold;
}
@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .heading {
    font-size: 2.5rem;
  }

  .footer {
    text-align: center;
  }

  .footer .box-container .box .payment-img {
    margin: 2rem auto;
  }
}
@media (min-aspect-ratio: 16/9) {
  .back-video {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .back-video {
    width: auto;
    height: 100%;
  }
}
@media screen and (min-width: 992px) {
  .product-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .product-col-r-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .product-items {
    grid-template-columns: repeat(3, 1fr);
  }
  .product {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .products .text-light {
    width: 50%;
  }
}

@media screen and (min-width: 1336px) {
  .product-items {
    grid-template-columns: repeat(4, 1fr);
  }
  .product-collection-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .flex {
    height: 60vh;
  }
  .product-col-left {
    height: 121.5vh;
  }
}
body {
  background-image: url("https://github.com/Matsya-03161013/matsya/blob/main/FISHERMAN.jpg?raw=true");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}
